  <template>
    <div>
      <div class="d-flex justify-content-center mt-2">
        <div class="min-h-screen d-flex py-12" style="overflow-x: auto;">
          <div v-for="(column, index) in columns" :key="index + 1"
            class="column-width removeScroll rounded ml-1 bg-white  p-1 overflow-auto position-relative"
            style="height: 80vh;">
            <div class="py-2 d-flex justify-content-between "
              style="top:-20px;background-color: white;z-index:100;font-size:17px">
              <span class="px-1 font-weight-bold">{{ column.title }}</span>
              <!-- <feather-icon icon="MoreVerticalIcon" size="21" class="cursor-pointer" /> -->
            </div>
            <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
            <draggable :list="column.sales" :animation="200" :class="column.title" ghost-class="ghost-card" group="tasks"
              :id="index" >
              <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
              <div v-for="(sales) in column.sales" @mouseenter="handelClickBorder(true, sales.id)"
                @mouseleave="handelClickBorder(false, sales.id)" :key="sales.id">
                <task-card  v-if="sales" :task="sales" class="mb-1 cursor-move"
                  :class="borderId[sales.id] ? 'border-primary border-lighten-2' : 'border-primary border-lighten-5'"></task-card>
              </div>
              <!-- </transition-group> -->
            </draggable>
          </div>
          <!-- <b-sidebar
                title="Update"
                :visible="active"
                bg-variant="white"
                width="400px"
                right
                backdrop
                shadow
              >
                <sidebar-content />
              </b-sidebar> -->

        </div>
      </div>
    </div>
  </template>

  <script>
  import draggable from "vuedraggable";
  import TaskCard from "./TaskCard.vue";
  import Ripple from 'vue-ripple-directive'
  // import Attachment from "../../../components/Attechment.vue";
  import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
  import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
  import flatPickr from "vue-flatpickr-component";
  import { ValidationProvider, ValidationObserver } from "vee-validate";



  import axios from '@/component/axios';;
  import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BMediaBody,
    BSidebar,
    BRow,
    BCol,
    BFormFile,
    VBToggle,
    BMedia,
    BMediaAside,
    BAvatar,
    BImg,
    BBadge,
    BLink,
    BFormInput,
    BFormGroup,
    BTabs,
    BTab,
    BFormTextarea,

  } from "bootstrap-vue";
  import SidebarContent from './SidebarContent.vue'
  // import columns from './data.json'
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      AppTimeline,
      AppTimelineItem,
      TaskCard,
      SidebarContent,
      // Attachment,
      BFormGroup,
      BSidebar,
      draggable,
      BFormInput,
      BFormFile,
      BRow,
      BCol,
      BAvatar,
      BBadge,
      BButton,
      BTabs,
      BTab,
      flatPickr,
      BFormTextarea,
      BImg
    },
    directives: {
      'b-toggle': VBToggle,
    },
    data() {
      return {
        active: false,
        borderId: {},
        test: [
          { 'data': 'test' },
          { 'data': 'img' },
        ],
        datetime: '',
        attachments: {
          att1: '',
          att2: '',
          att3: ''
        },
        fromTitle: '',
        toTitle: '',
        fromId: '',
        toId: '',
        documents: [],
        document: null,

        comments: [],
        comment: '',
        showRemarkPopUp: false,
        accessToken: localStorage.getItem("accessToken"),
        baseApi: process.env.VUE_APP_APIENDPOINT,
        loginUser: localStorage.getItem("userData"),
        sales: { },
        columns: [
          {
            title: 'To Do Task',
            sales: [
              {
                id:1,
                title: 'ETTINGS NEW BRANDING THEME GRID',
                taskno: "#2132",
                type:'Bug'
              },
              {
                id: 2,
                title: '[OFFER] UI ISSUE ON THE CREATE POPUP',
                taskno: "#2132",
                type: 'Bug'
              },
              {
                id: 3,
                title: '[OFFER] UI ISSUE ON THE CREATE POPUP',
                taskno: "#2132",
                type: 'Bug'
              },
              {
                id: 4,
                title: '[OFFER] UI ISSUE ON THE CREATE POPUP',
                taskno: "#2132",
                type: 'Bug'
              }
            ]
          },
          {
            title: 'This Week Plan',
            sales: []
          }, {
            title: 'Todays',
            sales: []
          }, {
            title: 'Overview',
            sales: []
          }, {
            title: 'Blocker',
            sales: []
          }, {
            title: 'In Progress',
            sales: []
          }, {
            title: 'Pause',
            sales: []
          }, {
            title: 'In QC',
            sales: []
          }, {
            title: 'Done',
            sales: []
          },
          {
            title: 'Rejected',
            sales: []
          }
        ],
      };
    },
    mounted() {
    },
    methods: {

      handelClickBorder(status, id) {
        this.borderId[id] = status
        this.$forceUpdate()
      },
    }
  };
  </script>

  <style scoped>
  .attechmentDiv {
    display: flex;
    max-height: 200px;
    max-width: 200px;
  }

  .font-weight-300 {
    font-weight: 300 !important;
  }

  .font-weight-700 {
    font-weight: 700 !important;
  }

  .column-width {
    min-width: 260px;
    max-width: 250px;
  }

  /* Unfortunately @apply cannot be setup in codesandbox,
  but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
  .ghost-card {
    opacity: 0.5;
    background: #F7FAFC;
    border: 1px solid #4299e1;
  }

  .removeScroll::-webkit-scrollbar {
    display: none;
  }

  .detailsForm {
    border: 1px solid #d8d6de;
  }

  .detailHeader {
    border-bottom: 1px solid #d8d6de;
  }

  .boxBorder {
    border: 1px solid #d8d6de;
  }

  .timelineBox {
    padding: 20px;
    display: flex;
  }

  .mainBox {
    padding: 10px;
    font-weight: bolder;
    min-height: 70px;
    width: 100%
  }

  .remarksDetails {
    padding: 20px;
    border: 1px solid #d8d6de;
    border-radius: 10px;
  }

  .sendButton {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }


  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }


  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.441);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 128, 128, 0.941);
  }
  </style>
  <style>
  @media (min-width: 992px) {
    .modal-jira {
      min-width: 990px;
      min-height: 90%;
    }
  }

  @media (min-width: 576px) and (max-width:992px) {
    .modal-jira {
      max-width: 700px;
    }
  }</style>
