<template>
  <div @click="$emit('showsidebar')" class="bg-white shadow rounded p-1 border border-white">
    <div class="d-flex mb-1 justify-content-between align-item-center">
      <!-- <badge v-if="task.type" :color="badgeColor">{{task.type}}</badge> -->
      <b-badge v-if="task.taskno" variant="light-success" class="badge-glow m-0">{{ task.taskno }}</b-badge>
      <div v-else></div>
      <!-- <span class="text-sm text-gray-600">{{ task.bookingdate }}</span> -->
    </div>
    <div class="">
      <p class="text-sm kanban-text font-weight-bold">{{ task.title  }}</p>
      <p class="text-sm">Type: <span class="font-weight-bold">{{task.type}}</span></p>


      <!-- <img
        class="rounded ml-3"
        width="40px"
        height="40px"
        src="https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg"
        alt="Avatar"
      > -->

    </div>
    <div>
    </div>
    <!-- <div class="d-flex justify-content-between align-item-center">
      <div class="d-flex">
        <div class="pr-1 d-flex align-items-end">
          <feather-icon icon="PaperclipIcon" size="18" class="cursor-pointer" />
          <span>2</span>
        </div>
        <div class="pr-1 d-flex align-items-end">
          <feather-icon icon="MessageSquareIcon" size="18" class="cursor-pointer" />
          <span>2</span>
        </div>
      </div>
      <b-avatar-group class="" size="25">
        <b-avatar v-for="avatar in avatars" :key="avatar.avatar" :src="avatar.avatar" class="pull-up" />
        <h6 class="align-self-center cursor-pointer mb-0" style="margin-left:8px">
          +42
        </h6>
      </b-avatar-group>
    </div> -->
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BMediaBody,
  BSidebar,
  VBToggle,
  BMedia,
  BMediaAside, BAvatarGroup,
  BAvatar,
  BImg,
  BBadge,
  BLink,
  BFormInput,
} from "bootstrap-vue";
export default {
  components: {
    BBadge,
    BButton, BAvatarGroup,
    BAvatar,
  },
  data() {
    return {
      avatars: [
        { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson' },
        // { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
        // { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
        // { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper' },
      ],
    }
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    badgeColor() {
      const mappings = {
        Design: "danger",
        "Feature Request": "primary",
        Backend: "info",
        QA: "success",
        default: "primary"
      };
      // const mappings = {
      //   Design: "light-danger",
      //   "Feature Request": "light-primary",
      //   Backend: "light-info",
      //   QA: "light-success",
      //   default: "light-primary"
      // };
      return mappings[this.task.type] || mappings.default;
    }
  },
};
</script>
<style></style>
